<template>
  <div class="frameMirror">
    <div class="container">
      <div class="left">
        <p class="title">{{content.title}}</p>
        <div class="typeItem" v-for="item in content.typeItem">
          <p class="prefix">{{item.prefix}}</p>
          <div class="suffix">
            <p v-for="item1 in item.suffix">{{item1}}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <video style="width: 80%" controls autoplay="autoplay" loop="loop" ref="video" >
          <source type="video/mp4">
          <source type="video/ogg">
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>
    <div class="radioGroup" @click="radioClick">
      <label for="1"><input type="radio" name="state" id="1" checked value="1"/>近视</label>
      <label for="2"><input type="radio" name="state" id="2" value="2"/>远视</label>
      <label for="3"><input type="radio" name="state" id="3" value="3"/>老花眼</label>
      <label for="4"><input type="radio" name="state" id="4" value="4"/>散光</label>
      <label  for="5" class="scene" @click="sceneClick"><img id="5" src="../../assets/img/drive.png" />场景体验</label>
    </div>
    <div class="btnGroup" >
      <button @click="go" style="display: none">返回</button>
      <button @click="hazardClick">高度近视的危害</button>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "frameMirror",
  data() {
    return {
      list: [],
      content: {},
      mvUrl: ''
    }
  },
  methods: {
    radioClick(e) {
      if(e.target.value){
        this.content = this.list[e.target.value - 1];
        this.mvUrl = this.content['vsrc'];
      }
    },
    go() {
      this.$router.go(-1);
    },
    sceneClick() {
      this.$router.push({path: '/PopularScienceHome/proFunction', query: {
          proId: 20
        }});
    },
    hazardClick() {
      this.$router.push({path: '/PopularScienceHome/myopiaHazard'});
    }
  },
  watch: {
    mvUrl(newValue, oldValue) {
      let video = this.$refs.video;
      video.src = this.mvUrl;
    }
  },
  created() {
    let arr = [
      {
          title: '近视眼',
          vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/myopic_eye.mp4',
          typeItem: [
        {
          prefix: '状态：',
          suffix: [
            '1 看远模糊,看近清楚(度数越高,需要越近才能看清)。',
            '2 视疲劳。',
            '3 外隐斜或外斜。'
          ]
        },
        {
          prefix: '分类：',
          suffix: [
            '轻度近视: -3.00D以类',
            '中度近视: -3.00D^-6.00D',
            '高度近视: -6.25D^-10.00D',
            '重度近视: -10.25D以上',
          ]
        },
        {
          prefix: '成因：',
          suffix: [
            '眼轴增长或屈光系统曲率过高'
          ]
        },
        {
          prefix: '矫正类型：',
          suffix: [
            '凹透镜'
          ]
        },
        {
          prefix: '矫正产品：',
          suffix: [
            '框架镜片 OK镜 RGP 软性隐形眼镜'
          ]
        },
      ]
      },
      {
        title: '远视眼',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/hyperopia.mp4',
        typeItem: [
          {
            prefix: '状态：',
            suffix: [
              '1 轻度远视: 年龄小远近视力不受影响,年龄大看远正常,看近模糊',
              '中度远视: 年龄小看远正常,看近模糊,年龄大远近模糊',
              '深度远视: 远近模糊',
              '2 视疲劳。',
              '3 内隐斜或内斜。'
            ]
          },
          {
            prefix: '分类：',
            suffix: [
              '轻度远视: +3.00D以类',
              '中度远视: +3.25D^+5.00D',
              '高度远视: >+5.00D'
            ]
          },
          {
            prefix: '成因：',
            suffix: [
              '眼轴较短或屈光系统的屈光力下降'
            ]
          },
          {
            prefix: '矫正类型：',
            suffix: [
              '凸透镜'
            ]
          },
          {
            prefix: '矫正产品：',
            suffix: [
              '框架眼镜(为主) 角膜接触镜少用'
            ]
          },
        ]
      },
      {
        title: '老视眼',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/presbyopia.mp4',
        typeItem: [
          {
            prefix: '状态：',
            suffix: [
              '40岁以上,看近不持久,吃力,模糊,喜欢强光(属于正常生理性老化现象)',
            ]
          },
          {
            prefix: '矫正类型：',
            suffix: [
              '看近凸透镜'
            ]
          },
          {
            prefix: '矫正产品：',
            suffix: [
              '老花镜,渐进片'
            ]
          },
        ]
      },
      {
        title: '散光',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/astigmatism_eye.mp4',
        typeItem: [
          {
            prefix: '状态：',
            suffix: [
              '远近重影不清晰,视物变形,头痛,头晕,流泪眯眼,严重视疲劳',
            ]
          },
          {
            prefix: '成因：',
            suffix: [
              '角膜 晶状体等屈光成分曲率不一致'
            ]
          },
          {
            prefix: '矫正类型：',
            suffix: [
              '柱镜片(举例:-1.00DC*180)'
            ]
          },
          {
            prefix: '矫正产品：',
            suffix: [
              '框架眼镜,RGP,散光软性隐形眼镜'
            ]
          },
        ]
      },
    ];
    this.list = arr;
    this.content = this.list[0];
    this.mvUrl = this.content['vsrc']
  }
}
</script>

<style scoped lang="scss">
.frameMirror {
  width: 100vw;
}
.container {
  width: 100%;
  display: flex;
  align-items: center;
}
.left {
  flex: 1;
  width: 100%;
  padding-left: 10px;
}
.right {
  flex: 1;
}
.left p {
  text-align: left;
}
.title {
  margin: 10px 0;
  width: 100%;
  font-size: 18px;
}
.typeItem {
  margin-top: 10px;
  overflow: hidden;
}
.typeItem .prefix {
  float: left;
  font-size: 18px;
}
.typeItem .suffix {
  float: left;
}
.typeItem .suffix p {
  font-size: 16px;
}
.radioGroup {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioGroup label {
  width: 100px;
  display: flex;
  align-items: center;
}
.radioGroup input {
  margin-right: 5px;
  font-size: 40px;
}
.scene img {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.btnGroup {
  display: flex;
  justify-content: center;
}
.btnGroup button {
  margin: 70px;
  padding: 10px 0;
  width: 210px;
  border: 1px solid #333;
  color: #333;
  background: transparent;
  font-size: 18px;
}

</style>
